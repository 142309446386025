<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">发布帖子</div>
      <div class="topRight" @click="goDrafts()">草稿箱</div>
    </div>
    <div class="yy"></div>
    <div class="title">
      <input type="text" placeholder="填写标题" v-model="trends.title"/>
    </div>
    <div class="content">
      <textarea placeholder="添加正文..." v-model="trends.content"></textarea>
    </div>
    <div class="uploader">
      <van-uploader
        v-model="imgList"
        :after-read="uploadImg"
        :max-count="9"
        multiple
        @delete="onDelete"
      ></van-uploader>
    </div>
    <div class="prompt">长按可拖动排序，第一张图为封面图（限9张）</div>
    <div class="position" @click="goposition()">
      <div><img src="../../image/quanz/wz.png" alt="" />所在位置</div>
      <div>请选择位置<img src="../../image/quanz/j.png" alt="" /></div>
    </div>
    <div class="position" @click="show = true">
      <div><img src="../../image/quanz/fw.png" alt="" />可见范围</div>
      <div>{{ range }}<img src="../../image/quanz/j.png" alt="" /></div>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    <div class="footer">
      <div @click="saveDraft"><img src="../../image/quanz/cg.png" alt="" /></div>
      <div @click="addTrends"><img src="../../image/quanz/fb.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
import { trendsDraftAdd, addTrends, trendsInfo } from "@/api/trends";
import {attUpload} from "@/api/circle";
import {Toast} from "vant";

export default {
  data() {
    return {
      show: false,
      actions: [
        { id: 0, name: "公开" },
        { id: 1, name: "仅自己可见" },
      ],
      range: "请选择可见范围",
      imgList:[],
      trends: {
        id: '',
        title: "",
        content: "",
        imgs: '',
        position: "",
        range: "",
      }
    };
  },
  mounted() {
    this.trends.id = this.$route.query.trendsId;
    if (this.trends.id !== '' && this.trends.id !== undefined) {
      this.getTrendsInfo();
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onSelect(item) {
      this.show = false;
      this.range = item.name;
      this.trends.range = item.id;
    },
    goposition() {
      this.$router.push({ path: "/positionPage" });
    },
    // 上传照片
    uploadImg(files) {
      console.log(files)
      let formData = new FormData();
      if (files.length > 1) {
        files.forEach((item) => {
          formData.append("files", item.file);
        });
      } else {
        formData.append("files", files.file);
      }
      attUpload(formData).then((res) => {
        console.log(res, "动态图片");
        if (res.data.code != 0) {
          Toast(res.data.msg);
        } else {
          // res.data.data.forEach((item) => {
          //   this.trends.imgs.push(item);
          // });
          this.imgList = res.data.data;
        }
      });
    },
    onDelete(file) {
      const index = this.imgList.indexOf(file);
      if (index > -1) {
        this.imgList.splice(index, 1);
      }
      console.log(this.imgList)
    },
    goDrafts(){
      this.$router.push({path:'/drafts'})
    },
    // 保存草稿
    saveDraft() {
      this.trends.imgs = JSON.stringify(this.imgList);
      this.trends.status = 1;
      trendsDraftAdd(this.trends).then(res => {
        if (res.data.code) {
          alert("保存成功")
        }
      })
    },
    // 发布动态
    addTrends() {
      this.trends.imgs = JSON.stringify(this.imgList)
      addTrends(this.trends).then(res => {
        this.$router.go(-1)
      })
    },
    // 根据id查询动态信息
    getTrendsInfo() {
      trendsInfo(this.trends.id).then(res => {
        this.trends.title = res.data.data.title;
        this.trends.content = res.data.data.content;
        this.trends.imgs = res.data.data.imgs;
        this.trends.position = res.data.data.position;
        this.trends.range = res.data.data.range;
        this.imgList = JSON.parse(res.data.data.imgs)
      })
    }
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  margin-right: 32px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
}
.yy {
  width: 750px;
  height: 20px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 0.0176;
  margin-top: 20px;
}
.title {
  width: 686px;
  height: 116px;
  border-bottom: 1px solid #f6f8fa;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.title input {
  font-size: 40px;
  border: none;
  width: 680px;
  /* height: 86px; */
  font-weight: 600;
}
.content textarea {
  width: 686px;
  height: 300px;
  margin-left: 32px;
  font-weight: 400;
  font-size: 32px;
  border: none;
  margin-top: 32px;
}
.uploader {
  display: flex;
  flex-wrap: wrap;
  margin-left: 32px;
}
/deep/.van-uploader__preview-image {
  width: 216px;
  height: 216px;
}

/deep/.van-uploader__upload {
  width: 216px;
  height: 216px;
}
.prompt {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 32px;
  margin-bottom: 40px;
}
.position {
  width: 686px;
  height: 104px;
  border-top: 1px solid #f6f8fa;
  margin-left: 32px;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.position div {
  display: flex;
  align-items: center;
}
.position img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.footer {
  display: flex;
  align-items: center;
  margin-top: 140px;
}
.footer div:nth-child(1) img {
  width: 198px;
  height: 84px;
  margin-left: 32px;
}
.footer div:nth-child(2) img {
  width: 456px;
  height: 84px;
  margin-left: 32px;
}
</style>
